import { replacePlaceholdersInString } from './stringUtilities';

/**
 * Takes a route name with placeholders (ie. /blog/post/:id/:slug), and an object with
 * replacements (ie. { ':id': 1, ':slug': 'some-title' }), and generates a route path
 * from it (ie. /blog/post/1/some-title)
 */

export const createPathFromRoute = (routeWithPlaceholders, replacements) => {
    return replacePlaceholdersInString(routeWithPlaceholders, replacements);
};
