const getSceneStyle = (draggableStyle, splash) => ({
    cursor: 'pointer',
    padding: 10,
    margin: 5,
    color: 'black',
    borderRadius: '7px',

    position: 'relative',
    backgroundImage: `url(${splash})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    display: 'inline-block',

    border: '1px solid black',

    width: '250px',
    height: '100px',
    userSelect: 'none',
    ...draggableStyle,
});

export default getSceneStyle;