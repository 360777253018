import { useMutation, useQueryClient } from 'react-query';
import { updateTestScenario } from '@repository/scenarioRepository';

const useUpdateTestScenario = (scenarioId) => {
    const queryClient = useQueryClient();
    const mutation = useMutation(data => {
        return updateTestScenario(scenarioId, data.id, data.data);
    }, {
        onSuccess: async () => {
            queryClient.invalidateQueries(['testscenarios', scenarioId]);
        }
    });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useUpdateTestScenario;