import { useQuery } from 'react-query';
import { fetchScenario } from '@repository/scenarioRepository';

const useScenario = (id) => {
    const { data, isLoading, isError, error } = useQuery(['scenario', id], () => fetchScenario(id), {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 10
    });

    return { data, isLoading, isError, error: error?.response.data.message };
};

export default useScenario;