import { useMutation, useQueryClient } from 'react-query';
import { deleteNavigatorScene } from '@repository/scenarioRepository';

const useDeleteNavigatorScene = (scenarioId) => {
    const queryClient = useQueryClient();
    const mutation = useMutation(sceneId => {
        return deleteNavigatorScene(scenarioId, sceneId);
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['scenario', scenarioId]);
            await queryClient.invalidateQueries(['navigatorScenes', scenarioId]);
        }
    });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useDeleteNavigatorScene;