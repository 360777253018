import {
    executeGetRequest,
    executePostRequest,
    executePutRequest,
    executeDeleteRequest
} from '@api/client';
import {
    createTestScenarioPath,
    createTestScenarioWithIdPath,
    createScenarioConditionsPath,
    createScenarioPath,
    scenariosPath,
    createConditionsPath,
    createNavigatorScenePath,
    createNavigatorScenePositionPath,
    createActionPath,
    createActionWithIdPath,
    createAttributesActionsPath,
    createAttributesConditionsPath,
    createAttributesStylesPath,
    createOverlaysPath,
    createOverlaysWithIdPath,
    createScenarioLivePath,
    createScenarioLivePathWithoutId,
    createScenePath,
    createNavigatorScenePathWithoutId
} from '@routing/apiUrls';

export const fetchScenarios = async () => {
    const [promise] = executeGetRequest(scenariosPath);
    const result = await promise;
    return result.data.scenarios;
};

export const fetchScenario = async (id) => {
    const [promise] = executeGetRequest(createScenarioPath(id));
    const result = await promise;
    return result.data;
};

export const updateScenario = async (id, data) => {
    const [promise] = executePutRequest(createScenarioPath(id), data);
    const result = await promise;
    return result.data.scenarios;
};

export const getScenes = async (scenarioId) => {
    const [promise] = executeGetRequest(createScenePath(scenarioId));
    const result = await promise;
    return result.data;
};

export const getScenarioConditions = async (scenarioId) => {
    const [promise] = executeGetRequest(createAttributesConditionsPath(scenarioId));
    const result = await promise;
    return result.data;
};

export const getScenarioStyles = async (scenarioId) => {
    const [promise] = executeGetRequest(createAttributesStylesPath(scenarioId));
    const result = await promise;
    return result.data;
};

export const getScenarioActions = async (scenarioId) => {
    const [promise] = executeGetRequest(createAttributesActionsPath(scenarioId));
    const result = await promise;
    return result.data;
};

export const getScenariosLive = async () => {
    const [promise] = executeGetRequest(createScenarioLivePathWithoutId());
    const result = await promise;
    return result.data;
};

export const setScenarioLive = async (scenarioId) => {
    const [promise] = executePostRequest(createScenarioLivePath(scenarioId));
    const result = await promise;
    return result.data;
};

export const createCondition = async (scenarioId, sceneId, data) => {
    const [promise] = executePostRequest(createScenarioConditionsPath(scenarioId, sceneId), data);
    const result = await promise;
    return result.data;
};

export const deleteCondition = async (scenarioId, sceneId, conditionId) => {
    const [promise] = executeDeleteRequest(createConditionsPath(scenarioId, sceneId, conditionId));
    const result = await promise;
    return result.data;
};

export const updateCondition = async (scenarioId, sceneId, conditionId, data) => {
    const [promise] = executePutRequest(createConditionsPath(scenarioId, sceneId, conditionId), data);
    const result = await promise;
    return result.data.scenarios;
};

export const getNavigatorScenes = async (scenarioId) => {
    const [promise] = executeGetRequest(createNavigatorScenePathWithoutId(scenarioId));
    const result = await promise;
    return result.data;
};

export const createNavigatorScenes = async (scenarioId, sceneId, data) => {
    const [promise] = executePostRequest(createNavigatorScenePath(scenarioId, sceneId), data);
    const result = await promise;
    return result.data;
};

export const updateNavigatorScenes = async (scenarioId, sceneId, data) => {
    const [promise] = executePutRequest(createNavigatorScenePath(scenarioId, sceneId), data);
    const result = await promise;
    return result.data;
};

export const deleteNavigatorScene = async (scenarioId, sceneId) => {
    const [promise] = executeDeleteRequest(createNavigatorScenePath(scenarioId, sceneId));
    const result = await promise;
    return result.data;
};

export const updateNavigatorScenePosition = async (scenarioId, data) => {
    const [promise] = executePutRequest(createNavigatorScenePositionPath(scenarioId, data.id), data);
    const result = await promise;
    return result.data;
};

export const getTestScenarios = async (scenarioId) => {
    const [promise] = executeGetRequest(createTestScenarioPath(scenarioId));
    const result = await promise;
    return result.data;
};

export const createTestScenario = async (scenarioId, data) => {
    const [promise] = executePostRequest(createTestScenarioPath(scenarioId), data);
    const result = await promise;
    return result.data;
};

export const deleteTestScenario = async (scenarioId, testScenarioId) => {
    const [promise] = executeDeleteRequest(createTestScenarioWithIdPath(scenarioId, testScenarioId));
    const result = await promise;
    return result.data;
};

export const updateTestScenario = async (scenarioId, testScenarioId, data) => {
    const [promise] = executePutRequest(createTestScenarioWithIdPath(scenarioId, testScenarioId), data);
    const result = await promise;
    return result.data;
};

export const createOverlay = async (scenarioId, sceneId, data) => {
    const [promise] = executePostRequest(createOverlaysPath(scenarioId, sceneId), data);
    const result = await promise;
    return result.data;
};

export const deleteOverlay = async (scenarioId, sceneId, overlayId) => {
    const [promise] = executeDeleteRequest(createOverlaysWithIdPath(scenarioId, sceneId, overlayId));
    const result = await promise;
    return result.data;
};

export const updateOverlay = async (scenarioId, sceneId, overlayId, data) => {
    const [promise] = executePutRequest(createOverlaysWithIdPath(scenarioId, sceneId, overlayId), data);
    const result = await promise;
    return result.data;
};


export const createActionOnClick = async (scenarioId, sceneId, overlayId, data) => {
    const [promise] = executePostRequest(createActionPath(scenarioId, sceneId, overlayId), data);
    const result = await promise;
    return result.data;
};

export const deleteActionOnClick = async (scenarioId, sceneId, overlayId, actionId) => {
    const [promise] = executeDeleteRequest(createActionWithIdPath(scenarioId, sceneId, overlayId, actionId));
    const result = await promise;
    return result.data;
};

export const updateActionOnClick = async (scenarioId, sceneId, overlayId, actionId, data) => {
    const [promise] = executePutRequest(createActionWithIdPath(scenarioId, sceneId, overlayId, actionId), data);
    const result = await promise;
    return result.data;
};
