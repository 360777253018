import { ReactComponent as TrashSVG } from "@images/trash.svg";
import { ReactComponent as PreviewSVG } from "@images/preview.svg";

const TestScenario = ({
  setDetailsOpen,
  setCurrentTestScenario,
  testScenario,
  handleDelete,
  handlePreviewSubmit,
}) => (
  <div className="mb-6 border-primary w-11/12">
    <div className="cursor-pointer flex p-2 bg-primary text-secondary border border-primary w-100 row-start-1 text-left text-md items-center col-start-1 col-end-4 relative">
      <div
        className="w-full h-full absolute flex items-center"
        onClick={() => {
          setCurrentTestScenario(testScenario);
          setDetailsOpen(true);
        }}
      >
        {testScenario?.title}
      </div>
      <div className="flex flex-row ml-auto h-25 gap-2 top-0 right-0">
        <button
          className="z-50 w-1/2 p-2 rounded bg-red-500 hover:bg-red-700 flex items-center justify-center text-white"
          onClick={() => handleDelete(testScenario)}
        >
          <TrashSVG className="icon--little" />
        </button>
        <button
          className="z-50 w-1/2 p-2 rounded bg-secondary hover:bg-secondaryDarken flex items-center justify-center text-white"
          onClick={handlePreviewSubmit}
        >
          <PreviewSVG className="icon--little" />
        </button>
      </div>
    </div>
  </div>
);

export default TestScenario;
