import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import Toast from "@components/primitives/toast/Toast";
import useScenario from "@hooks/useScenario";
import useScenarioLive from "@hooks/useScenarioLive";
import Navigation from '@components/features/navigation/Navigation';
import { PagePath } from "@routing/appUrls";
import { ReactComponent as SaveSVG } from '@images/save.svg';
import { i18n } from "@utils/i18n";

const Header = () => {
    const { scenarioId } = useParams();
    const { data: scenario } = useScenario(scenarioId);
    const { mutate: mutateScenarioLive } = useScenarioLive(scenarioId);

    const handleSave = () => {
        Swal.fire({
            title: 'Wil je opslaan?',
            html: 'Als je de huidige versie opslaat, wordt deze versie gebruikt, en wordt dan toegankelijk voor iedereen. Wil je dit?',
            showDenyButton: true,
            icon: 'warning',
            confirmButtonText: 'Opslaan',
            confirmButtonColor: '#0e6f9e',
            denyButtonColor: '#083e58',
            denyButtonText: `Niet opslaan`,
            showCloseButton: true,
            focusDeny: true,
        }).then((result) => {
            if (result.isConfirmed) {
                mutateScenarioLive();
                Toast('success', 'Sucessvol opgeslagen en live gezet');
            } else if (result.isDenied) {
                Toast('info', 'Niet opgeslagen');
            }
        });
    };

    const handleBack = () => {
        Toast('success', 'Opgeslagen');
    };

    return (
        <div className='fixed flex items-center top-0 left-0 bg-secondary z-50 w-full h-16 px-5'>
            <div className='flex items-center w-full h-full'>
                <ul className='flex text-white list-none items-center gap-5'>
                    <li>
                        <Link to={PagePath.scenarios} className='rounded p-2 bg-secondary hover:bg-blue-500  text-white flex items-center' onClick={() => handleBack()}>
                            &lt; Terug
                        </Link>
                    </li>
                    <li>
                        <button className='flex flex-row gap-2 rounded p-2 bg-secondary hover:bg-blue-500  text-white items-center' onClick={() => handleSave()}>
                            Opslaan
                            <SaveSVG className='h-4 w-4' />
                        </button>
                    </li>
                </ul>
            </div>

            <Navigation />

            <div className='flex items-center justify-end w-full h-full text-white m-8'>
                <p>{i18n.tabs.title}: {scenario?.title}</p>
            </div>
        </div>
    );
};

export default Header;