import {
    Link,
    useMatch,
    useResolvedPath
} from 'react-router-dom';

const NavLink = ({ children, to, or = '/', ...props }) => {
    let resolvedTo = useResolvedPath(to);
    let matchTo = useMatch({ path: resolvedTo.pathname });

    let resolvedOr = useResolvedPath(or);
    let matchOr = useMatch({ path: resolvedOr.pathname });

    return (
        <div className={matchTo || matchOr ? 'w-full bg-primary hover:bg-gray-300 rounded-lg text-primary p-2' : 'w-full rounded-lg p-2 hover:bg-blue-500 '}>
            <Link
                to={to}
                className='flex flex-row items-center justify-center w-full gap-2'
                {...props}
            >
                {children}
            </Link>
        </div>
    );
};

export default NavLink;
