import { useContext } from 'react';
import { scenarioContext } from '@components/pages/scenarioDetailPage/components/ScenarioContext';

const useScenarioContext = () => {
    const { activeOverlayId, setActiveOverlayId } = useContext(scenarioContext);

    return {
        activeOverlayId,
        setActiveOverlayId,
    }
}

export default useScenarioContext;
