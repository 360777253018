import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { ReactComponent as TrashSVG } from "@images/trash.svg";
import { ReactComponent as AlignLeftSVG } from "@images/align-left.svg";
import { ReactComponent as AlignCenterSVG } from "@images/align-center.svg";
import { ReactComponent as AlignRightSVG } from "@images/align-right.svg";
import { i18n } from "@utils/i18n";
import useScenarioStyles from "@hooks/useScenarioStyles";
import useScenarioConditions from "@hooks/useScenarioCondtions";
import useScenario from "@hooks/useScenario";
import useScenarioContext from "@components/pages/scenarioDetailPage/hooks/useScenarioContext";
import ConditionButton from "./components/ConditionButton";
import AlignCheckbox from "./components/AlignCheckbox";
import TextBoxWithColor from "./components/TextBoxWithColor";
import Toast from "@components/primitives/toast/Toast";

const TextOverlaySettings = ({ handleDelete, handleChange }) => {
  const maxCharacter = 64;
  const { activeOverlayId } = useScenarioContext();
  const { scenarioId, sceneId } = useParams();

  const { data: scenarioConditions } = useScenarioConditions(scenarioId);
  const { data: scenario } = useScenario(scenarioId);
  const { data: styles } = useScenarioStyles(scenarioId);

  const [styleValue, setStyleValue] = useState({});
  const [alignValue, setAlignValue] = useState("");
  const [textValue, setTextValue] = useState("");
  const [highlitedText, setHighlitedText] = useState("");

  const activeScene = scenario?.navigator.find(
    (navigatorScene) => navigatorScene.id === sceneId
  );
  const activeOverlay = activeScene?.overlays.find(
    (overlay) => overlay.id === activeOverlayId
  );

  const textareaRef = useRef();

  useEffect(() => {
    if (!activeOverlay) {
      return;
    }
    setStyleValue(activeOverlay.textData.style);
    setAlignValue(activeOverlay.textData.align);
    setText(activeOverlay.textData.text);
    // eslint-disable-next-line
  }, [activeOverlay]);

  const handleConditionClick = (conditionPrefix) => {
    const selectionStart = textareaRef.current.selectionStart;
    const selectionEnd = textareaRef.current.selectionEnd;

    let newValue =
      textValue.substring(0, selectionStart) +
      conditionPrefix +
      textValue.substring(selectionEnd, textValue.length);
    setText(newValue);
  };

  const handleTextAreaChange = (e) => {
    setText(e.target.value);
  };

  //a setter for the text area that checks if the amount of characters is not more than 64 else show an error toast
  const setText = (text) => {
    if (text.length > maxCharacter) {
      return Toast("error", i18n.toast.textTooLong);
    }
    setTextValue(text);
    handleInput(text);
  };

  //#region temp mark place

  function handleInput(updatedText) {
    var highlitedText = applyHighlights(updatedText);
    setHighlitedText(highlitedText);
  }

  function applyHighlights(text) {
    return text
      .replace(/\n$/g, "\n\n")
      .replace(
        /\{\{([a-z]+)\}\}/g,
        '<mark class="highlited-text-interaction">$&</mark>'
      );
  }

  //#endregion

  return (
    <form
      className="flex flex-col gap-6"
      onBlur={handleChange}
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="flex gap-6">
        <TextBoxWithColor
          textValue={textValue}
          handleTextAreaChange={handleTextAreaChange}
          textareaRef={textareaRef}
          highlitedText={highlitedText}
          characterCount={textValue.length}
          maxCharacter={maxCharacter}
        />
        <div className="flex flex-col gap-2 overflow-y-visible overflow-x-hidden">
          {scenarioConditions
            ? scenarioConditions?.map((condition) => (
                <ConditionButton
                  key={condition.id}
                  textValue={textValue}
                  condition={condition}
                  handleConditionClick={handleConditionClick}
                />
              ))
            : null}
        </div>
      </div>
      <div className="flex flex-row gap-5">
        <div className="flex flex-col gap-8 w-1/4">
          <label forhtml="stijl-dropdown">Stijl</label>
          <label>Uitlijning</label>
        </div>
        <div className="flex flex-col gap-5 w-full">
          <Select
            value={styleValue ? styleValue : ""}
            options={styles?.map((style) => {
              return {
                value: style.id,
                label: style.value,
              };
            })}
            className="w-1/3"
            placeholder={i18n.select}
            onChange={(e) => {
              handleChange({ target: { name: "style", ...e } });
              setStyleValue(e);
            }}
          />
          <div className="flex flex-row gap-5">
            <AlignCheckbox
              icon={
                <AlignLeftSVG
                  className="w-6 h-6"
                  aria-label="Align Left Icon"
                />
              }
              alignValue={alignValue}
              type="left"
            />
            <AlignCheckbox
              icon={
                <AlignCenterSVG
                  className="w-6 h-6"
                  aria-label="Align Center Icon"
                />
              }
              alignValue={alignValue}
              type="center"
            />
            <AlignCheckbox
              icon={
                <AlignRightSVG
                  className="w-6 h-6"
                  aria-label="Align Right Icon"
                />
              }
              alignValue={alignValue}
              type="right"
            />
          </div>
        </div>
      </div>
      <button
        className="bg-red-500 hover:bg-red-700 p-2 rounded w-3em ml-auto flex justify-center text-white absolute bottom-5 right-5"
        onClick={() => handleDelete()}
      >
        <TrashSVG aria-label="Delete" />
      </button>
    </form>
  );
};

export default TextOverlaySettings;
