import { useParams } from "react-router-dom";
import EditorLayout from "@components/EditorLayout";
import useScenarioContext from "@components/pages/scenarioDetailPage/hooks/useScenarioContext";
import useScenarioStyles from "@hooks/useScenarioStyles";
import useScenario from "@hooks/useScenario";
import useUpdateOverlay from "@components/features/overlays/hooks/useUpdateOverlay";
import TextOverlaySettings from "@components/features/overlays/components/TextOverlaySettings";
import ButtonOverlaySettings from "@components/features/overlays/components/ButtonOverlaySettings";
import useDeleteOverlay from "@components/features/overlays/hooks/useDeleteOverlay";
import { i18n } from "@utils/i18n";

//creates the inner square on the interaction page
const OverlaysPage = () => {
  const { sceneId, scenarioId } = useParams();
  const { activeOverlayId } = useScenarioContext();

  const { mutate: mutateDeleteOverlay } = useDeleteOverlay(scenarioId);

  const { data: styles } = useScenarioStyles(scenarioId);
  const { data: scenario } = useScenario(scenarioId);
  const { mutate: mutateUpdateOverlay } = useUpdateOverlay(scenario?.id);

  const activeScene = scenario?.navigator.find(
    (navigatorScene) => navigatorScene.id === sceneId
  );

  const activeOverlay = activeScene?.overlays?.find(
    (overlay) => overlay.id === activeOverlayId
  );

  const handleChange = (e) => {
    if (e.target.name === "value") return;
    if (e.target.name === "text") {
      activeOverlay.textData.text = e.target.value;
    }

    if (e.target.name === "style") {
      const currentStyle = styles.find((style) => style.id === e.target.value);
      activeOverlay.textData.style = {
        value: e.target.value,
        label: e.target.label,
        className: currentStyle.className,
      };
    }

    if (e.target.name === "align") {
      activeOverlay.textData.align = e.target.value;
    }

    if (e.target.name === "onClick") {
      activeOverlay.onClick = {
        ...activeOverlay.onClick,
        action: {
          value: e.target.value,
          label: e.target.label,
        },
      };
    }

    if (e.target.name === "onClickValue") {
      activeOverlay.onClick = {
        ...activeOverlay.onClick,
        value: e.target.value,
      };
    }

    mutateUpdateOverlay({
      sceneId: sceneId,
      overlayId: activeOverlay.id,
      overlay: activeOverlay,
    });
  };

  const handleDelete = () => {
    mutateDeleteOverlay({
      sceneId,
      overlayId: activeOverlay.id,
    });
  };

  const OverlaySettings = {
    text: TextOverlaySettings,
    button: ButtonOverlaySettings,
  }[activeOverlay?.type];

  return (
    <EditorLayout viewMode="interactive">
      <h1 className="text-2xl my-2">Interactie</h1>
      <div>
        {!activeScene ? (
          <>{i18n.overlays.didntFoundScene}</>
        ) : !activeOverlay ? (
          <>{i18n.overlays.didntFoundOverlay}</>
        ) : (
          <OverlaySettings
            handleDelete={handleDelete}
            handleChange={handleChange}
          />
        )}
      </div>
    </EditorLayout>
  );
};

export default OverlaysPage;
