import { Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from 'react-router-dom';
import { i18n } from '@utils/i18n';
import MediaItem from "@components/features/mediaLibrary/components/MediaItem";
import EditorLayout from '@components/EditorLayout';
import useMedia from '@hooks/useMedia';

//creates the scene cards in the media tab
const MediaLibrary = () => {
    const { scenarioId } = useParams();
    const { media } = useMedia(scenarioId);

    return (
        <EditorLayout viewMode='media'>
            <Droppable droppableId="mediacomposer" isDropDisabled={true}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {media?.length ? media.map((mediaItem, index) => (
                            <Draggable
                                key={mediaItem.sceneId}
                                draggableId={`${mediaItem.sceneId}-mediacomposer`}
                                index={index}>
                                {(provided, snapshot) => (
                                    <>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                backgroundImage: `url(${mediaItem.splash})`

                                            }}
                                            className='cursor-pointer p-2 m-1 text-black rounded relative inline-block border border-black bg-primary w-64 h-24 bg-center bg-no-repeat select-none'
                                        >
                                            <div className='absolute top-0 left-0 p-0 w-full h-full opacity-75 bg-black hover:opacity-0'></div>
                                            <MediaItem mediaItem={mediaItem} />
                                        </div>
                                        {
                                            snapshot.isDragging && (
                                                <div
                                                    className='cursor-pointer p-2 m-1 text-black rounded relative inline-block border border-black bg-primary w-64 h-24 bg-center bg-no-repeat select-none'
                                                    style={{
                                                        backgroundImage: `url(${mediaItem.splash})`
                                                    }}
                                                >
                                                    <div className='absolute top-0 left-0 p-0 w-full h-full opacity-75 bg-black'></div>
                                                    <MediaItem mediaItem={mediaItem} />
                                                </div>
                                            )
                                        }
                                    </>
                                )}
                            </Draggable>
                        )) : i18n.media.noScenes}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </EditorLayout>
    );
};

export default MediaLibrary;