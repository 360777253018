export const secondsToDisplay = (sec) => {
    // 3661 : 1:01:01 (aka 1 hour, 1 minute and 1 second)
    //   61 :    1:01
    //    1 :    0:01

    const hours = Math.floor(sec / 3600);
    const displayHours = (hours === 0) ? `` : `${hours}:`;

    const minutes = Math.floor((sec - (hours * 3600)) / 60);
    const displayMinutes = (minutes === 0) ? `0:` : `${(hours !== 0 && minutes < 10) ? `0:` : ``}${minutes}:`;

    const seconds = Math.floor((sec - (minutes * 60) - (hours * 3600)));
    const displaySeconds = (seconds < 10) ? `0${seconds}` : `${seconds}`;

    const displayTime = `${displayHours}${displayMinutes}${displaySeconds}`;
    return displayTime;
};

// secondsToDisplay(0);
// secondsToDisplay(1);
// secondsToDisplay(59);
// secondsToDisplay(60);
// secondsToDisplay(61);
// secondsToDisplay(3599);
// secondsToDisplay(3600);
// secondsToDisplay(3601);
// secondsToDisplay(7203);
// secondsToDisplay(24 * 3600 + 1);