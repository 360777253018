import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import ScenariosPage from '@components/pages/scenariosPage/ScenariosPage';
import ScenarioContext from '@components/pages/scenarioDetailPage/components/ScenarioContext';
import ErrorBoundary from '@components/features/errorBoundry/ErrorBoundry';
import EditorPage from '@components/pages/editorPage/EditorPage';
import RoutesWrapper from '@components/primitives/routesWrapper/RoutesWrapper';

const queryClient = new QueryClient();

const App = () => (
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <ScenarioContext>
                <ErrorBoundary>
                    <RoutesWrapper>
                        <Route index path="/" element={<ScenariosPage />} />
                        <Route index path="scenarios" element={<ScenariosPage />} />
                        <Route path="scenarios/:scenarioId/*" element={<EditorPage />} />
                    </RoutesWrapper>
                </ErrorBoundary>
            </ScenarioContext>
        </BrowserRouter>
    </QueryClientProvider>
);

export default App;