import Swal from "sweetalert2";

const Toast = (type, title) => {
    return Swal.fire({
        toast: true,
        icon: type,
        title: title,
        animation: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });
};

export default Toast;