import { useMutation, useQueryClient } from 'react-query';
import { updateNavigatorScenes } from '@repository/scenarioRepository';

const useUpdateNavigatorScene = (scenarioId) => {
    const queryClient = useQueryClient();
    const mutation = useMutation(scene => {
        const data = {
            description: scene.description,
            navigatorTitle: scene.navigatorTitle,
            overlays: scene.overlays
        };
        return updateNavigatorScenes(scenarioId, scene.id, data);
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['scenario', scenarioId]);
            await queryClient.invalidateQueries(['navigatorScenes', scenarioId]);
        }
    });

    const { mutate, isLoading, isError, data, error } = mutation;
    return { mutate, isLoading, isError, data, error };
};

export default useUpdateNavigatorScene;