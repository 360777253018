import { useParams } from 'react-router-dom';
import useScenario from '@hooks/useScenario';
import EditorLayout from '@components/EditorLayout';
import Settings from '@components/features/sceneSettings/components/Settings';
import { i18n } from '@utils/i18n';

const Conditions = () => {
    const { scenarioId, sceneId } = useParams();
    const { data: scenario } = useScenario(scenarioId);

    const activeScene = scenario?.navigator.find((scene) => {
        return scene.id === sceneId;
    });

    return (
        <EditorLayout viewMode='conditions'>
            <div className='flex flex-col'>
                {!activeScene ? (
                    <>
                        {i18n.editor.didntFoundScene}
                    </>
                ) : (
                    <Settings />
                )}
            </div>
        </EditorLayout >
    );
};

export default Conditions;