import React, { createContext, useState } from "react";

export const scenarioContext = createContext({});

const ScenarioContext = ({ children }) => {
    const [lastActiveScene, setLastActiveScene] = useState();
    const [activeOverlayId, setActiveOverlayId] = useState(null);

    return (
        <scenarioContext.Provider value={{
            lastActiveScene,
            setLastActiveScene,

            // Active overlay
            activeOverlayId,
            setActiveOverlayId,
        }}>
            {children}
        </scenarioContext.Provider>
    );
};

export default ScenarioContext;