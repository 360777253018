import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { PagePath } from '@routing/appUrls';

const ErrorPage = ({ error, errorInfo }) => {
    if (error === 404) {
        errorInfo = 'Deze pagina is niet gevonden, probeer later nog eens.';
    }

    return (
        <div className='flex justify-center items-center w-full h-full'>
            <div className='w-fit h-fit p-2 flex items-center flex-col'>
                <Helmet>
                    <title>{`Error ${error}`} - Scenario Builder</title>
                </Helmet>
                <h1 className='text-primary text-5xl m-5'>{error}</h1>
                <h5 className='text-lg'>{errorInfo}</h5>
                <Link to={PagePath.scenarios} className='m-5 rounded bg-blue-500 hover:bg-blue-700 text-white p-2'>
                    Terug naar hoofdpagina
                </Link>
            </div>
        </div>
    );
};

export default ErrorPage;