import { useState } from "react";
import { useParams } from 'react-router-dom';
import TestScenarios from './components/testScenarios/TestScenarios';
import useCreateTestScenario from "@hooks/useCreateTestScenario";
import useDeleteTestScenario from "@hooks/useDeleteTestScenario";
import useUpdateTestScenario from "@hooks/useUpdateTestScenario";
import useScenarioConditions from "@hooks/useScenarioCondtions";
import useGetTestScenarios from "@hooks/useGetTestScenarios";
import EditorLayout from '@components/EditorLayout';
import Conditions from "./components/Conditions";
import Preview from "@components/features/preview/Preview";

const PreviewPage = () => {
    const { scenarioId } = useParams();
    const { data: testScenarios } = useGetTestScenarios(scenarioId);

    const [previewScenario, setPreviewScenario] = useState();

    const [currentTestScenario, setCurrentTestScenario] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);

    const { data: conditions } = useScenarioConditions(scenarioId);

    const { mutate: createTestScenario } = useCreateTestScenario(scenarioId);
    const { mutate: deleteTestScenario } = useDeleteTestScenario(scenarioId);
    const { mutate: updateTestScenario } = useUpdateTestScenario(scenarioId);

    const handleCreate = () => {
        const conditionsOptions = conditions?.map((condition) => {
            return ({
                conditionId: condition.id,
                value: condition.values[0] || '',
            });
        });

        const newTestScenario = {
            title: 'Nieuw testscenario',
            conditions: conditionsOptions
        };

        createTestScenario(newTestScenario);
    };

    const handleDelete = (testScenario) => {
        setCurrentTestScenario(null);
        setDetailsOpen(false);
        setPreviewScenario(null);
        deleteTestScenario(testScenario?.id);
    };

    const handlePreviewSubmit = (e) => {
        e.preventDefault();
        setPreviewScenario(currentTestScenario);
        setDetailsOpen(false);
    };

    const handleChange = (e, testScenarioId, conditionId = null) => {
        const scenarioCondition = conditions?.find((condition) => condition.id === conditionId);
        const data = {
            name: e?.target?.name || scenarioCondition?.name,
            value: e?.label ? { id: e.value, value: e.label } : false || e?.target?.value,
        };

        updateTestScenario({
            id: testScenarioId,
            data: {
                name: data.name,
                value: data.value,
            }
        });
    };

    return (
        <EditorLayout viewMode='preview'>
            <div className='grid h-full gap-6 grid-cols-previewScreen'>
                Preview gedeelte nog in onderhoud
                <div className='gap-3.5 box-border overflow-y-visible scroll-smooth no-wrap min-h-full col-start-1 row-start-1'>
                    <TestScenarios setCurrentTestScenario={setCurrentTestScenario} setDetailsOpen={setDetailsOpen} handleCreate={handleCreate} handleChange={handleChange} handlePreviewSubmit={handlePreviewSubmit} handleDelete={handleDelete} testScenarios={testScenarios} />
                </div>

                {detailsOpen
                    ? <div className='box-border row-start-1 col-start-2'>
                        <Conditions currentTestScenario={currentTestScenario} handleChange={handleChange} />
                    </div>
                    : null
                }

                {previewScenario
                    ? <Preview showOverlays={false} />
                    : null
                }
            </div>
        </EditorLayout>
    );
};

export default PreviewPage;