import { useParams, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import {
    createMediaPath, createOverlayPath,
    createPreviewPath,
    createConditionsPath,
    PagePath
} from '@routing/appUrls';
import { ReactComponent as MediaSVG } from '@images/media.svg';
import { ReactComponent as PreviewSVG } from '@images/preview.svg';
import { ReactComponent as LayersSVG } from '@images/layers.svg';
import { i18n } from '@utils/i18n';
import useScenario from '@hooks/useScenario';
import NavLink from '@components/primitives/navLink/NavLink';
import { scenarioContext } from '@components/pages/scenarioDetailPage/components/ScenarioContext';
import { ReactComponent as ConditionSVG2 } from '@images/condition2.svg';

//creates the mnavigation buttons the top of the screen

const Navigation = () => {
    const { scenarioId, sceneId } = useParams();
    const { data: scenario } = useScenario(scenarioId);
    const { lastActiveScene, setLastActiveScene } = useContext(scenarioContext);
    const navigate = useNavigate();

    const onSceneSettingsClick = () => {
        if (scenario?.navigator.length > 0) {
            setLastActiveScene(scenario?.navigator[0].id);
            navigate(createConditionsPath(scenarioId, scenario?.navigator[0].id));
            return;
        }
    };

    return (
        <div className='flex justify-between items-center z-50 w-full text-white'>
            <ul className='flex list-none space-x-5 mx-5 w-full items-center'>
                <li className='rounded-lg w-full'>
                    <NavLink to={createMediaPath(scenarioId)} or={PagePath.mediaWithoutMedia} >
                        {i18n.tabs.media}
                        <MediaSVG className='w-4 h-4' aria-label={i18n.tabs.media} />
                    </NavLink>
                </li>
                <li className='rounded-lg w-full'>
                    <NavLink to={createOverlayPath(scenarioId, sceneId || lastActiveScene)} or={PagePath.overlaysWithoutSceneId}>
                        {i18n.tabs.overlays}
                        <LayersSVG className='w-4 h-4' aria-label={i18n.tabs.overlays} />
                    </NavLink>
                </li>
                <li className='rounded-lg w-full'>
                    <NavLink to={createConditionsPath(scenarioId, sceneId || lastActiveScene)} or={PagePath.conditionsWithoutSceneId} onClick={() => onSceneSettingsClick()}>
                        {i18n.tabs.conditions}
                        <ConditionSVG2 className='w-4 h-4' aria-label={i18n.tabs.overlays} />
                    </NavLink>
                </li>
                <li className='rounded-lg w-full'>
                    <NavLink to={createPreviewPath(scenarioId)}>
                        {i18n.tabs.preview}
                        <PreviewSVG className='w-4 h-4' aria-label={i18n.tabs.preview} />
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default Navigation;
