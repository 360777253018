import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { ReactComponent as TrashSVG } from '@images/trash.svg';
import useMutateCondition from '@hooks/useMutateCondition';
import { i18n } from '@utils/i18n';
import FormInput from '@components/primitives/form/FormInput';
import FormSelect from '@components/primitives/form/FormSelect';
import useScenarioConditions from '@hooks/useScenarioCondtions';

const Condition = ({ condition, deleteCondition }) => {
    const { scenarioId, sceneId } = useParams();

    const { data: conditions } = useScenarioConditions(scenarioId);

    const [contextId, setContextId] = useState();
    const [triggerId, setTriggerId] = useState();
    const [value, setValue] = useState('');
    const { mutate } = useMutateCondition(scenarioId, sceneId, condition?.id);

    const conditionSettings = conditions?.find((condition) => {
        return condition.id === contextId;
    });

    useEffect(() => {
        //eslint-disable-next-line
        if (!contextId || condition?.context === contextId && condition?.value === value && condition?.trigger === triggerId) {
            return;
        }

        mutate({
            context: contextId,
            trigger: triggerId,
            value: value,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextId, triggerId, value]);

    const findContextById = (contextId) => {
        return conditions?.find((condition) => {
            return condition?.id === contextId;
        });
    };

    const findTriggerById = (triggerId) => {
        return conditionSettings?.triggers.find((trigger) => {
            return trigger?.id === triggerId;
        });
    };

    const setContextById = (contextId) => {
        const context = findContextById(contextId);
        setContextId(context?.id);
    };

    useEffect(() => {
        if (!condition) {
            return;
        }

        setContextById(condition?.context);
        setTriggerId(condition?.trigger);
        setValue(condition?.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition]);

    const onContextChange = (e) => {
        const contextId = e.value;
        setContextById(contextId);

        // Reset fields trigger and value after changing context
        const _conditionSettings = conditions?.find((condition) => {
            return condition?.id === contextId;
        });
        setTriggerId(_conditionSettings?.triggers[0]?.id);
        setValue(_conditionSettings?.values[0]?.id || '');
    };

    const onTriggerChange = (e) => {
        const triggerId = e.value;
        setTriggerId(triggerId);
    };

    const onValueChange = (e) => {
        const value = e.value || e.target.value;
        setValue(value);
    };

    const valueField = {
        text: <FormInput type="text" value={value} onChange={onValueChange} />,
        date: <FormInput type="date" value={value} onChange={onValueChange} />,
        number: <FormInput type="number" value={value} onChange={onValueChange} />,
        select: <FormSelect value={value} onChange={onValueChange} options={conditionSettings?.values} />,
    }[conditionSettings?.format];

    return (
        <div className='flex flex-row gap-2 p-4 bg-primary border border-primary w-full lg:flex-col xl:flex-row'>
            <Select
                options={conditions?.map((_condition) => {
                    return {
                        value: _condition.id,
                        label: _condition.name,
                    };
                })}
                className='w-full'
                value={{ value: contextId, label: findContextById(contextId)?.name }}
                placeholder={i18n.select}
                onChange={onContextChange}
            />
            <Select
                options={conditionSettings?.triggers.map((_trigger) => {
                    return {
                        value: _trigger.id,
                        label: _trigger.value,
                    };
                })}
                className='w-full'
                value={{ value: triggerId, label: findTriggerById(triggerId)?.value }}
                placeholder={i18n.select}
                onChange={onTriggerChange}
            />
            {valueField ? (
                <div className='w-full'>{valueField}</div>
            ) : null}
            <button className='rounded bg-red-500 hover:bg-red-700 flex justify-center items-center lg:w-full xl:w-1/4 h-9 text-white' onClick={() => deleteCondition(condition)}><TrashSVG /></button>
        </div>
    );
};

export default Condition;