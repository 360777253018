import { i18n } from "@utils/i18n";

const FormInput = ({ type, value, onChange }) => (
    <input
        className='form-control'
        type={type}
        value={value}
        placeholder={i18n.media.placeholderConditionValue}
        onChange={onChange}
    />
);

export default FormInput;