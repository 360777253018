const ToolbarItem = ({ Icon, type, name, handleAddOverlay }) => (
  <button
    className="flex justify-center items-center m-1 p-3 bg-green-500 hover:bg-green-700 text-white rounded-md h-14 w-auto border-none"
    onClick={() => handleAddOverlay(type)}
    title={name}
  >
    <Icon aria-label={name} />
  </button>
);

export default ToolbarItem;
