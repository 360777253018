import classNames from 'classnames';
// import PreviewOverlay from '@components/features/preview/components/PreviewOverlay';
import PreviewOverlayV2 from '@components/features/preview/components/PreviewOverlayV2';

const PreviewOverlayText = ({ overlay, overlaysRef }) => {
    const { textData } = overlay;
    const { align, text, style } = textData;
    const classes = classNames('preview__overlay', 'preview__overlayText', style?.className, {
        'preview__overlayText--alignLeft': align === 'left',
        'preview__overlayText--alignCenter': align === 'center',
        'preview__overlayText--alignRight': align === 'right',
    });

    if (!overlaysRef?.current) {
        return null;
    }

    return (
        <PreviewOverlayV2
            overlay={overlay}
            className={classes}
            overlaysRef={overlaysRef}
            renderChild={(vw) => (
                <div style={{
                    fontSize: vw * 4,
                }}>{text}</div>
            )}
        />
    );
};

export default PreviewOverlayText;
