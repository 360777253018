import { useMutation } from 'react-query';
import { updateOverlay } from '@repository/scenarioRepository';

const useUpdateOverlay = (scenarioId) => {
    const mutation = useMutation(data => {
        const { sceneId, overlayId, overlay } = data;
        return updateOverlay(scenarioId, sceneId, overlayId, overlay);
    });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useUpdateOverlay;