import { useMutation, useQueryClient } from 'react-query';
import { createCondition } from '@repository/scenarioRepository';

const useCreateCondition = (scenarioId, sceneId) => {
    const queryClient = useQueryClient();

    const mutation = useMutation((condition) => createCondition(scenarioId, sceneId, condition),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['scenario', scenarioId]);
                await queryClient.invalidateQueries(['navigatorScenes', scenarioId]);
            }
        });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useCreateCondition;