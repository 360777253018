import { useMutation } from 'react-query';
import { setScenarioLive } from '@repository/scenarioRepository';

const useScenarioLive = (scenarioId) => {
    const mutation = useMutation(() => {
        return setScenarioLive(scenarioId);
    });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useScenarioLive;