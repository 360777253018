import { useQuery } from 'react-query';
import { getNavigatorScenes } from '@repository/scenarioRepository';

const useGetNavigatorScenes = (scenarioId) => {
    const { data, isLoading, isError } = useQuery(['navigatorScenes', scenarioId], () => getNavigatorScenes(scenarioId), {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 10
    });

    return { data, isLoading, isError };
};

export default useGetNavigatorScenes;