import ReactLoading from 'react-loading';

const Loader = ({ type = 'spin' }) => {
    return (
        <div className="relative flex justify-center items-center h-full">
            <ReactLoading
                type={type}
                color='lightblue'
                height={100}
                width={100}
            />
        </div>
    );
};

export default Loader;