const ConditionButton = ({ textValue, condition, handleConditionClick }) => (
    <button
        className={`bg-secondary text-white rounded p-2 hover:bg-blue-500 ${textValue.includes(condition.prefix) ? 'bg-green-900' : null}`}
        key={condition.id}
        onClick={() => handleConditionClick(condition.prefix)}
    >
        {condition.prefix}
    </button>
);

export default ConditionButton;