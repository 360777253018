import Select from "react-select";
import { useParams } from "react-router-dom";
import { i18n } from "@utils/i18n";
import useScenarioConditions from "@hooks/useScenarioCondtions";

const Conditions = ({ currentTestScenario, handleChange }) => {
    const { scenarioId } = useParams();
    const { data: conditions } = useScenarioConditions(scenarioId);

    const findScenarioConditionById = (id) => {
        return conditions?.find((condition) => condition.id === id);
    };

    return (
        <form onChange={(e) => handleChange(e, currentTestScenario?.id)} className='flex flex-col gap-2'>
            {
                currentTestScenario ? currentTestScenario?.conditions?.map((condition) => {
                    const currentCondition = findScenarioConditionById(condition.conditionId);

                    const options = currentCondition.values.map((value) => {
                        return {
                            value: value.id,
                            label: value.value,
                        };
                    });

                    const Element = {
                        select: <Select
                            required
                            key={condition?.id}
                            placeholder={i18n.select}
                            onChange={(e) => handleChange(e, currentTestScenario?.id, currentCondition?.id)}
                            id={currentCondition?.id}
                            name={currentCondition.name}
                            defaultValue={{ value: condition.value.id, label: condition.value.value }}
                            options={options}
                        />,
                        date: <input
                            required
                            type='date'
                            key={condition?.id}
                            id={currentCondition?.id}
                            className='block w-full p-form text-base font-normal leading-normal text-gray-900 bg-white appearance-none rounded border border-primary'
                            name={currentCondition.name}
                            style={{ height: 95 }}
                            defaultValue={condition.value}
                        />,
                        number: <input
                            required
                            type='number'
                            key={condition?.id}
                            id={currentCondition?.id}
                            className='block w-full p-form text-base font-normal leading-normal text-gray-900 bg-white appearance-none rounded border border-primary'
                            name={currentCondition.name}
                            defaultValue={condition.value}
                        />,
                        text: <input
                            required
                            key={condition?.id}
                            id={currentCondition?.id}
                            className='block w-full p-form text-base font-normal leading-normal text-gray-900 bg-white appearance-none rounded border border-primary'
                            name={currentCondition.name}
                            defaultValue={condition.value}
                        />,
                    }[currentCondition.format];

                    return (
                        <div key={condition.conditionId}>
                            {currentCondition.name}
                            {Element}
                        </div>
                    );
                }) : null
            }
        </form>
    );
};

export default Conditions;