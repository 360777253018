import { useQuery } from 'react-query';
import { getScenes } from '../repository/scenarioRepository';

const useMedia = (scenarioId) => {
    const { data: media, isLoading } = useQuery(['media', scenarioId], () => getScenes(scenarioId), {
        refetchOnWindowFocus: true,
    });
    return { media, isLoading };
};

export default useMedia;