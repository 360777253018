import { useRef } from "react";

const TextBoxWithColor = ({
  textValue,
  handleTextAreaChange,
  textareaRef,
  highlitedText,
  characterCount,
  maxCharacter,
}) => {
  const backdropRef = useRef();
  var counterClass =
    maxCharacter - characterCount < 5
      ? "float-right text-xs text-red-600"
      : "float-right text-xs text-gray-400";

  function handleScroll() {
    var scrollTop = textareaRef.current.scrollTop;
    backdropRef.current.scrollTop = scrollTop;
  }

  return (
    <div className="w-full">
      <div className="highlight-container w-full h-32 block m-0">
        <div
          className="backdrop w-full h-32 overflow-auto bg-white absolute z-[1] pointer-events-none"
          ref={backdropRef}
        >
          <div className="highlights whitespace-pre-wrap text-transparent p-2.5 tracking-normal">
            <div dangerouslySetInnerHTML={{ __html: highlitedText }} />
          </div>
        </div>
        <textarea
          ref={textareaRef}
          className="textarea-interaction w-full h-32 overflow-auto block m-0 bg-transparent absolute z-[2] p-2.5 tracking-normal rounded-none resize-none"
          maxLength={maxCharacter}
          id="text-field"
          name="text"
          value={textValue}
          onScroll={() => handleScroll()}
          onChange={(e) => handleTextAreaChange(e)}
        />
      </div>

      <p className={counterClass}>
        {characterCount}/{maxCharacter}
      </p>
    </div>
  );
};

export default TextBoxWithColor;
