import { ReactComponent as TextSVG } from '@images/text-icon.svg';
import { ReactComponent as ButtonSVG } from '@images/button-icon.svg';
import ToolbarItem from './components/ToolbarItem';

const OverlayToolbar = ({ handleAddOverlay }) => (
    <div className='bg-primary border border-primary box-border row-start-1 col-start-1 flex flex-col w-5em h-full p-1'>
        <ToolbarItem Icon={TextSVG} name='Tekst' type='text' handleAddOverlay={handleAddOverlay} />
        <ToolbarItem Icon={ButtonSVG} name='Knop' type='button' handleAddOverlay={handleAddOverlay} />
    </div>
);

export default OverlayToolbar;