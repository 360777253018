import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { ReactComponent as TrashSVG } from '@images/trash.svg';
import { ReactComponent as AlignLeftSVG } from '@images/align-left.svg';
import { ReactComponent as AlignCenterSVG } from '@images/align-center.svg';
import { ReactComponent as AlignRightSVG } from '@images/align-right.svg';
import { i18n } from '@utils/i18n';
import useScenarioStyles from '@hooks/useScenarioStyles';
import useScenarioActions from '@hooks/useScenarioActions';
import useScenarioConditions from '@hooks/useScenarioCondtions';
import useScenario from '@hooks/useScenario';
import useScenarioContext from '@components/pages/scenarioDetailPage/hooks/useScenarioContext';
import ConditionButton from './components/ConditionButton';
import AlignCheckbox from './components/AlignCheckbox';

const ButtonOverlaySettings = ({ handleDelete, handleChange }) => {
    const { scenarioId, sceneId } = useParams();
    const { activeOverlayId } = useScenarioContext();
    const { data: scenario } = useScenario(scenarioId);

    const [styleValue, setStyleValue] = useState({});
    const [actionValue, setActionValue] = useState({});
    const [actionValueValue, setActionValueValue] = useState("");
    const [alignValue, setAlignValue] = useState("");
    const [textValue, setTextValue] = useState("");

    const { data: scenarioConditions } = useScenarioConditions(scenarioId);
    const { data: styles } = useScenarioStyles(scenarioId);
    const { data: actions } = useScenarioActions(scenarioId);

    const activeScene = scenario?.navigator.find((navigatorScene) => navigatorScene.id === sceneId);

    const activeOverlay = activeScene?.overlays.find((overlay) => overlay.id === activeOverlayId);

    const textareaRef = useRef();

    useEffect(() => {
        if (!activeOverlay) {
            return;
        }

        setStyleValue(activeOverlay?.textData.style);
        setTextValue(activeOverlay?.textData.text);
        setAlignValue(activeOverlay?.textData.align);
        setActionValue(activeOverlay?.onClick);
        setActionValueValue(activeOverlay?.onClick.value);
    }, [activeOverlay]);

    const handleConditionClick = (conditionPrefix) => {
        const selectionStart = textareaRef.current.selectionStart;
        const selectionEnd = textareaRef.current.selectionEnd;

        let newValue =
            textValue.substring(0, selectionStart) +
            conditionPrefix +
            textValue.substring(selectionEnd, textValue.length);
        setTextValue(newValue);
    };

    const handleTextAreaChange = (e) => {
        setTextValue(e.target.value);
    };

    return (
        <div>
            <form className='flex flex-col gap-6' onChange={handleChange} onSubmit={(e) => e.preventDefault()}>
                <div className='flex gap-6'>
                    <textarea ref={textareaRef} className='form-control' maxLength={64} id='text-field' name='text' value={textValue} onChange={(e) => handleTextAreaChange(e)} />
                    <div className='flex flex-col gap-2 overflow-y-visible overflow-x-hidden'>
                        {scenarioConditions ? scenarioConditions?.map((condition) => (
                            <ConditionButton key={condition.id} textValue={textValue} condition={condition} handleConditionClick={handleConditionClick} />
                        )) : null}
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='flex flex-col gap-8 w-1/4'>
                        <label>Stijl</label>
                        <label>Uitlijning</label>
                        <label>Actie bij klik</label>
                    </div>
                    <div className='flex flex-col gap-5 w-full'>
                        <Select
                            value={styleValue}
                            className="w-1/3"
                            options={
                                styles?.map((style) => {
                                    return {
                                        value: style.id,
                                        label: style.value,
                                    };
                                })
                            }
                            placeholder={i18n.select}
                            onChange={(e) => {
                                handleChange({ target: { name: 'style', ...e } });
                                setStyleValue(e);
                            }}
                        />
                        <div className='flex flex-row gap-5'>
                            <AlignCheckbox icon={<AlignLeftSVG className='w-6 h-6' aria-label='Align Left Icon' />} alignValue={alignValue} type='left' />
                            <AlignCheckbox icon={<AlignCenterSVG className='w-6 h-6' aria-label='Align Center Icon' />} alignValue={alignValue} type='center' />
                            <AlignCheckbox icon={<AlignRightSVG className='w-6 h-6' aria-label='Align Right Icon' />} alignValue={alignValue} type='right' />
                        </div>
                        <div className='gap-5 flex w-full'>
                            <Select
                                value={actionValue.action}
                                options={
                                    actions?.map((actionOnClick) => {
                                        return {
                                            value: actionOnClick.id,
                                            label: actionOnClick.label,
                                        };
                                    })
                                }
                                placeholder={i18n.select}
                                className='w-full'
                                onChange={(e) => {
                                    handleChange({ target: { name: 'onClick', ...e } });
                                    setActionValue(e);
                                }}
                            />
                            <input className='form-control' name='onClickValue' defaultValue={actionValueValue}></input>
                        </div>
                    </div>
                </div>
                <button className='bg-red-500 hover:bg-red-700 p-2 rounded w-3em ml-auto flex justify-center text-white absolute bottom-5 right-5' onClick={() => handleDelete()}>
                    <TrashSVG aria-label='Trash Icon' />
                </button>
            </form >
        </div>
    );
};

export default ButtonOverlaySettings;