import { useQuery } from 'react-query';
import { getScenarioConditions } from '@repository/scenarioRepository';

const useScenarioConditions = (scenarioId) => {
    const { data, isLoading, isError } = useQuery(['conditions', scenarioId], () => getScenarioConditions(scenarioId), {
        refetchOnWindowFocus: true,
    });

    return { data, isLoading, isError };
};

export default useScenarioConditions;