import { useParams } from "react-router-dom";
import { i18n } from "@utils/i18n";
import useScenarioConditions from "@hooks/useScenarioCondtions";
import TestScenario from "./TestScenario";
import { ReactComponent as TestSVG } from "@images/preview.svg";

const TestScenarios = ({
  setCurrentTestScenario,
  handleCreate,
  setDetailsOpen,
  handleChange,
  handlePreviewSubmit,
  handleDelete,
  testScenarios,
}) => {
  const { scenarioId } = useParams();
  const { data: conditions } = useScenarioConditions(scenarioId);

  if (conditions?.length < 1) {
    return <h5 className="text-primary">{i18n.preview.noConditions}</h5>;
  }

  return (
    <>
      <button
        className="bg-green-500 hover:bg-green-700 flex items-center gap-2 text-white p-2 rounded my-2"
        onClick={() => handleCreate()}
      >
        {i18n.preview.addTest}
        <TestSVG className="w-4 h-4" />
      </button>
      <div className="overflow-auto max-h-85">
        {testScenarios
          ? testScenarios.map((testScenario, index) => (
              <TestScenario
                key={index}
                setDetailsOpen={setDetailsOpen}
                setCurrentTestScenario={setCurrentTestScenario}
                handleChange={handleChange}
                testScenario={testScenario}
                handlePreviewSubmit={handlePreviewSubmit}
                handleDelete={handleDelete}
              />
            ))
          : null}
      </div>
    </>
  );
};

export default TestScenarios;
