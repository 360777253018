import React from 'react';
import { Route, useParams } from 'react-router-dom';
import MediaLibrary from '@components/features/mediaLibrary/MediaLibrary';
import ScenarioDragDrop from '@components/pages/scenarioDetailPage/components/ScenarioDragDrop';
import PreviewPage from '@components/pages/testPage/PreviewPage';
import Conditions from '@components/features/sceneSettings/Conditions';
import ErrorPage from '@components/pages/errorPage/ErrorPage';
import useScenario from '@hooks/useScenario';
import RoutesWrapper from '@components/primitives/routesWrapper/RoutesWrapper';
import OverlaysPage from '@components/pages/overlaysPage/OverlaysPage';

const EditorPage = () => {
    const { scenarioId } = useParams();
    const { error } = useScenario(scenarioId);

    if (error) {
        return (
            <ErrorPage error={404} />
        );
    }

    return (
        <ScenarioDragDrop>
            <RoutesWrapper>
                <Route path='/' element={<MediaLibrary />} />
                <Route path="/media" element={<MediaLibrary />} />
                <Route path="/overlays/:sceneId/*" element={<OverlaysPage />} />
                <Route path="/overlays/*" element={<OverlaysPage />} />
                <Route path="/conditions/:sceneId/*" element={<Conditions />} />
                <Route path="/conditions/*" element={<Conditions />} />
                <Route path="/preview" element={<PreviewPage />} />
            </RoutesWrapper>
        </ScenarioDragDrop>
    );
};

export default EditorPage;