import { useContext } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as TrashSVG } from '@images/trash.svg';
import getSceneStyle from "@utils/getSceneStyle";
import { createOverlayPath, createConditionsPath } from '@routing/appUrls';
import { i18n } from "@utils/i18n";
import NavigatorItem from "@components/features/navigator/components/NavigatorItem";
import useGetNavigatorScenes from "@hooks/useGetNavigatorScenes";
import { scenarioContext } from "@components/pages/scenarioDetailPage/components/ScenarioContext";
import useMedia from "@hooks/useMedia";
import useDeleteNavigatorScene from "@hooks/useDeleteNavigatorScene";

//creates the scene cards at the bottom of the screen
const Navigator = ({ viewMode }) => {
    const { scenarioId, sceneId } = useParams();
    const { setLastActiveScene } = useContext(scenarioContext);
    const { data: navigatorScenes } = useGetNavigatorScenes(scenarioId);
    const { mutate: deleteNavigatorScene } = useDeleteNavigatorScene(scenarioId);
    const { media } = useMedia(scenarioId);

    const onDeleteButton = (scene) => {
        deleteNavigatorScene(scene.id);
    };

    

    return (
        <Droppable droppableId="navigator" direction="horizontal">
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='h-60 overflow-x-auto scroll-smooth flex items-center p-4 bg-primary border border-primary box-border justify-start w-full border-25 row-start-2 col-start-1 col-end-3 gap-5'
                >
                    {navigatorScenes?.length > 0 ? navigatorScenes.map((scene, index) => (
                        <Draggable key={scene.id} draggableId={`${scene.id}-navigator`} index={index} disableInteractiveElementBlocking={true}>
                            {(provided) => {
                                const classElement = classNames('min-w-250', {
                                    'activeScene': sceneId === scene.id,
                                });

                                const currentScene = media?.find((scenarioScene) => scenarioScene.sceneId === scene.sceneId);

                                const navigatorPath = viewMode === 'interactive' 
                                    ? createOverlayPath(scenarioId, scene.id)
                                    : viewMode === 'conditions'
                                        ? createConditionsPath(scenarioId, scene.id)
                                        : window.location.pathname;
                                        
                                return (
                                    <div className='flex flex-col'>
                                        <div className='w-9 h-9 flex mx-auto'>
                                            {viewMode === 'media'
                                                ?
                                                <TrashSVG className='cursor-pointer w-9 h-9 p-2 text-white flex flex-row bg-red-500 hover:bg-red-700 rounded-full mx-auto' aria-label='Delete Icon' onClick={() => onDeleteButton(scene)} />
                                                : null
                                            }
                                        </div>
                                        <Link
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getSceneStyle(
                                                provided.draggableProps.style,
                                                currentScene?.splash
                                                )}
                                                to={navigatorPath}
                                                className={classElement}
                                                onClick={() => setLastActiveScene(scene?.id)}
                                                >
                                            <div className='absolute top-0 left-0 p-0 w-full h-full opacity-75 bg-black hover:opacity-0'></div>
                                            <NavigatorItem scene={scene} />
                                        </Link>
                                    </div>
                                );
                            }}
                        </Draggable>
                    )) : <p>{i18n.editor.noScenes}</p>}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default Navigator;