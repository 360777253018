import { useQuery } from 'react-query';
import { getScenarioActions } from '@repository/scenarioRepository';

const useScenarioActions = (scenarioId) => {
    const { data, isLoading, isError } = useQuery(['actions', scenarioId], () => getScenarioActions(scenarioId), {
        refetchOnWindowFocus: true,
    });

    return { data, isLoading, isError };
};

export default useScenarioActions;