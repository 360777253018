export const i18n = {
  scenarios: "Scenario's",
  noScenarios:
    "Geen scenario's beschikbaar, dit kan komen doordat er een fout is of omdat er daadwerkelijk geen scenario's beschikbaar zijn.",
  tabs: {
    editor: "Editor",
    media: "Media",
    preview: "Voorbeeld",
    overlays: "Interactie",
    save: "Opslaan",
    title: "Scenario",
    conditions: "Condities",
  },
  toast: {
    noSceneSelected: "Geen scene geselecteerd",
    textTooLong: "De tekst is te lang, probeer een korter tekst",
  },
  editor: {
    noSceneSelected: "Geen scene geselecteerd",
    didntFoundScene: "Geen scene gevonden",
  },
  overlays: {
    didntFoundOverlay: "Selecteer overlay",
    didntFoundScene: "Selecteer een scene",
  },
  media: {
    selectScene: "Selecteer scene",
    addCondition: "Voeg conditie toe",
    placeholderDescription: "Beschrijving",
    placeholderTitle: "Titel",
    noScenes: "Geen scenes beschikbaar",
    placeholderConditionValue: "Geef waarde in",
  },
  preview: {
    noConditions: "Geen condities beschikbaar",
    previewDescription: "Voorbeeld",
    noScenario: "Geen scenario",
    defaultScenario: "Standaard scenario",
    addTest: "Voeg testsenario toe",
  },
  select: "Selecteer",
};
