import { useParams } from 'react-router-dom';
import useScenario from '@hooks/useScenario';
import PreviewOverlayText from '@components/features/preview/components/PreviewOverlayText';
import { useRef } from 'react';

const PreviewOverlays = () => {
    const overlaysRef = useRef(null);
    const { sceneId, scenarioId } = useParams();
    // const { activeOverlayId } = useScenarioContext();
    const { data: scenario } = useScenario(scenarioId);
    const activeScene = scenario?.navigator.find((navigatorScene) => navigatorScene.id === sceneId);

    if (!activeScene?.overlays?.length) {
        return null;
    }

    return (
        <div className="previewScreen__overlays" ref={overlaysRef}>
            {activeScene.overlays.map((overlay) => {
                const Overlay = {
                    button: PreviewOverlayText,
                    text: PreviewOverlayText,
                }[overlay.type];

                return (
                    <Overlay key={overlay.id} overlay={overlay} overlaysRef={overlaysRef} />
                )
            })}
        </div>
    )
}

export default PreviewOverlays;
