import axios from "axios";

const defaultTimeoutLength = 4000; // 4 seconds

const axiosInstance = axios.create({
  baseURL: "https://api.editor.martvanweeghel.nl",
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
  },
});

export const executeGetRequest = (path) => {
  let cancel = null;
  // Todo:
  // const cancelToken = new axiosInstance.CancelToken((c) => (cancel = c));

  const promise = axiosInstance.get(path, {
    // cancelToken,
    timeout: defaultTimeoutLength,
  });

  return [promise, cancel];
};

export const executePutRequest = (path, data) => {
  let cancel = null;
  // Todo:
  // const cancelToken = new axios.CancelToken((c) => (cancel = c));

  const promise = axiosInstance.put(path, data, {
    // cancelToken,
    timeout: defaultTimeoutLength,
  });

  return [promise, cancel];
};

export const executePostRequest = (path, data) => {
  let cancel = null;
  // Todo:
  // const cancelToken = new axios.CancelToken((c) => (cancel = c));

  const promise = axiosInstance.post(path, data, {
    // cancelToken,
    timeout: defaultTimeoutLength,
  });

  return [promise, cancel];
};

export const executeDeleteRequest = (path, data) => {
  let cancel = null;
  // Todo:
  // const cancelToken = new axios.CancelToken((c) => (cancel = c));

  const promise = axiosInstance.delete(path, {
    // cancelToken,
    timeout: defaultTimeoutLength,
    data,
  });

  return [promise, cancel];
};
