import { useMutation, useQueryClient } from 'react-query';
import { createNavigatorScenes } from '@repository/scenarioRepository';

const useCreateNavigatorScene = (scenarioId) => {
    const queryClient = useQueryClient();
    const mutation = useMutation(data => {
        return createNavigatorScenes(scenarioId, data.draggedScene.sceneId, data);
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['scenario', scenarioId]);
            await queryClient.invalidateQueries(['navigatorScenes', scenarioId]);
        }
    });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useCreateNavigatorScene;