import Select from "react-select";
import { i18n } from "@utils/i18n";

const FormInput = ({ value, onChange, options }) => {
    //eslint-disable-next-line
    const selectValue = options.map((option) => {
        if (option.id === +value) {
            return {
                value: option.id,
                label: option.value
            };
        }
    });

    const selectOptions = options.map((option) => {
        return {
            value: option.id,
            label: option.value
        };
    });

    return (
        <Select
            onChange={onChange}
            options={selectOptions}
            value={selectValue}
            placeholder={i18n.select}
        />
    );
};

export default FormInput;