import { useMutation, useQueryClient } from 'react-query';
import { updateCondition } from '@repository/scenarioRepository';

const useMutateCondition = (scenarioId, sceneId, conditionId) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(condition => {
        return updateCondition(scenarioId, sceneId, conditionId, condition);
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['scenario', scenarioId]);
            await queryClient.invalidateQueries(['navigatorScenes', scenarioId]);
        }
    });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useMutateCondition;