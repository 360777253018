import { scenarioContext } from '@components/pages/scenarioDetailPage/components/ScenarioContext';
import moment from 'moment';
import 'moment/locale/nl';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { createMediaPath } from '@routing/appUrls';

const Scenario = ({ scenario, liveScenario, isLive }) => {
    const { setLastActiveScene } = useContext(scenarioContext);

    return (
        <Link to={createMediaPath(scenario.id, scenario.sceneId)} onClick={() => setLastActiveScene(scenario?.sceneId)}>
            <div className='cursor-pointer inline-block w-4/5 h-40 m-1 text-secondary relative border-primary border rounded-md bg-primary hover:bg-gray-200'>
                <div className='p-5'>
                    <h5 className='text-lg mb-3'>{scenario?.title}</h5>
                    <p className='text-md'>{scenario?.description}</p>
                    {isLive ?
                        <div>
                            <p className='absolute top-0 right-0 bg-red-500 m-2 text-center text-white w-20 h-6'>
                                LIVE
                            </p>
                            <small>
                                Laatst aangepast: {moment(liveScenario.timestamp).fromNow()}
                            </small>
                        </div>
                        : <div>
                            <p className='absolute top-0 right-0 bg-gray-500 m-2 text-center text-white w-20 h-6'>
                                NIET LIVE
                            </p>
                            <small>
                                Laatst aangepast: {moment(scenario.timestamp).fromNow()}
                            </small>
                        </div>
                    }
                </div>
            </div>
        </Link>
    );
};

export default Scenario;