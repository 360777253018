import {
    Routes,
    Route,
} from 'react-router-dom';
import ErrorPage from '@components/pages/errorPage/ErrorPage';

const RoutesWrapper = ({ children }) => (
    <Routes>
        {children}
        <Route path="*" element={<ErrorPage error={404} />} />
    </Routes>
);

export default RoutesWrapper;