import { createPathFromRoute } from '@utils/routeGenerator';
const appTitle = 'TVG Scenario Editor';

export const TitlePath = {
    scenarios: `${appTitle} - Scenarios`,
    editor: `${appTitle}`,
    media: `${appTitle} - Media`,
    preview: `${appTitle} - Preview`,
    proxy: `${appTitle} - Proxy`,
};

export const PagePath = {
    scenarios: '/scenarios',
    media: '/scenarios/:scenarioId/media',
    mediaWithoutMedia: '/scenarios/:scenarioId',
    overlays: '/scenarios/:scenarioId/overlays/:sceneId',
    overlaysWithoutSceneId: '/scenarios/:scenarioId/overlays',
    conditions: '/scenarios/:scenarioId/conditions/:sceneId',
    conditionsWithoutSceneId: '/scenarios/:scenarioId/conditions',
    preview: '/scenarios/:scenarioId/preview',
};

export const createMediaPath = (scenarioId, sceneId) => {
    return createPathFromRoute(PagePath.media, {
        ':scenarioId': scenarioId,
        ':sceneId': sceneId || '',
    });
};

export const createOverlayPath = (scenarioId, sceneId) => {
    return createPathFromRoute(PagePath.overlays, {
        ':scenarioId': scenarioId,
        ':sceneId': sceneId || '',
    });
};

export const createConditionsPath = (scenarioId, sceneId) => {
    return createPathFromRoute(PagePath.conditions, {
        ':scenarioId': scenarioId,
        ':sceneId': sceneId || '',
    });
};

export const createPreviewPath = (scenarioId) => {
    return createPathFromRoute(PagePath.preview, {
        ':scenarioId': scenarioId,
    });
};