import { useParams } from "react-router-dom";
import { ReactComponent as TextSVG } from "@images/text-icon.svg";
import { ReactComponent as ButtonSVG } from "@images/button-icon.svg";
import { ReactComponent as ConditionSVG2 } from "@images/condition2.svg";
import { secondsToDisplay } from "@components/primitives/dates";
import useMedia from "@hooks/useMedia";

const NavigatorItem = ({ scene }) => {
  const { scenarioId } = useParams();
  const { media } = useMedia(scenarioId);

  const findSceneByNavigatorSceneId = (sceneId) => {
    return media?.find((mediaItem) => mediaItem.sceneId === sceneId);
  };

  const textOverlays = scene?.overlays.filter(
    (overlay) => overlay.type === "text"
  );
  const buttonOverlays = scene?.overlays.filter(
    (overlay) => overlay.type === "button"
  );

  return (
    <>
      <div className="text-white absolute max-w-75">
        {findSceneByNavigatorSceneId(scene?.sceneId)?.sceneTitle}
      </div>
      <div className="text-white absolute max-w-75 bottom-10 italic">
        {scene.description ? scene.description : null}
      </div>
      <div className="flex flex-row text-white m-1 absolute left-0 bottom-0">
        {scene.conditions.length > 0 ? (
          <ConditionSVG2 className="w-6 h-6" aria-label="Condition" />
        ) : null}
        {textOverlays?.length > 0 ? (
          <TextSVG className="w-6 h-6" aria-label="Text" />
        ) : null}
        {buttonOverlays?.length > 0 ? (
          <ButtonSVG className="w-6 h-6" aria-label="Button" />
        ) : null}
      </div>
      <div className="text-white absolute right-2 bottom-0">
        {secondsToDisplay(
          findSceneByNavigatorSceneId(scene?.sceneId)?.duration
        )}
      </div>
    </>
  );
};

export default NavigatorItem;
