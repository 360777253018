import { useParams } from "react-router-dom";
import Navigator from "@components/features/navigator/Navigator";
import PreviewScreen from "@components/features/preview/Preview";
import useScenario from "@hooks/useScenario";
import useMedia from "@hooks/useMedia";
import Header from "@components/features/header/Header";
import { i18n } from "@utils/i18n";
import Overlays from "@components/features/overlays/Overlays";
import useCreateOverlay from "@components/features/overlays/hooks/useCreateOverlay";
import useScenarioContext from "@components/pages/scenarioDetailPage/hooks/useScenarioContext";
import OverlayToolbar from "@components/features/overlays/components/OverlayToolbar";
import Toast from "@components/primitives/toast/Toast";

const EditorLayout = ({ viewMode, children }) => {
  // viewMode = MEDIA | INTERACTIVE | CONDITIONS | PREVIEW
  const { setActiveOverlayId } = useScenarioContext();
  const { scenarioId, sceneId } = useParams();
  const { data: scenario } = useScenario(scenarioId);

  const { media } = useMedia(scenarioId);

  const { mutate: mutateCreateOverlay } = useCreateOverlay(scenarioId);

  const activeScene = scenario?.navigator.find((scene) => {
    return scene.id === sceneId;
  });

  const currentMedia = media?.find((media) => {
    return media.sceneId === activeScene?.sceneId;
  });

  const handleAddOverlay = (type) => {
    if (!activeScene) {
      return Toast("error", i18n.toast.noSceneSelected);
    }

    setActiveOverlayId(null);

    if (type === "text") {
      mutateCreateOverlay({
        sceneId: activeScene?.id,
        overlay: {
          type: "text",
          position: {
            width: 50,
            height: 25,
            left: 10,
            top: 15,
          },
          timeData: { from: 0, to: 100 },
          textData: {
            text: "tekst...",
            align: "left",
            style: null,
          },
          onClick: {},
        },
      });
      return;
    }

    mutateCreateOverlay({
      sceneId: activeScene?.id,
      overlay: {
        type: "button",
        position: {
          width: 50,
          height: 25,
          left: 10,
          top: 15,
        },
        timeData: { from: 0, to: 100 },
        textData: {
          text: "knop...",
          align: "left",
          style: null,
        },
        onClick: {},
      },
    });
  };

  return (
    <main>
      <div className="relative grid gap-6 grid-cols-2 grid-rows-editor h-screen p-5 pt-20">
        <Header />
        {viewMode === "media" || viewMode === "preview" ? (
          <>
            <div className="p-4 bg-primary border border-primary box-border col-start-1 row-start-1">
              <div className="relative border border-primary mt-2 overflow-auto scroll-smooth p-4 h-85">
                {children}
              </div>
            </div>
            <div className="flex flex-col gap-3.5 p-3.5 bg-primary border-primary border col-start-2 row-start-1 box-border">
              <div className="relative flex flex-row h-full">
                <div className="w-full overflow-hidden">
                  <PreviewScreen />
                </div>
              </div>
            </div>
          </>
        ) : null}

        {viewMode === "interactive" ? (
          <>
            <div className="flex flex-col gap-3.5 p-3.5 bg-primary border-primary border col-start-1 row-start-1 box-border">
              <div className="relative flex flex-row h-full">
                <OverlayToolbar handleAddOverlay={handleAddOverlay} />
                <div className="w-full overflow-hidden">
                  <PreviewScreen showOverlays={true} />
                  <Overlays />
                </div>
              </div>
            </div>
            <div className="p-4 bg-primary border border-primary box-border col-start-2 row-start-1">
              <h1 className="text-xl">{currentMedia?.sceneTitle}</h1>
              <h1 className="text-xl">
                <em>{activeScene?.description}</em>
              </h1>
              <div className="relative border border-primary mt-2 overflow-auto scroll-smooth p-4 h-85">
                {children}
              </div>
            </div>
          </>
        ) : null}

        {viewMode === "conditions" ? (
          <>
            <div className="p-4 bg-primary border border-primary box-border col-start-1 col-end-3 row-start-1">
              <div className="relative border border-primary mt-2 overflow-auto scroll-smooth p-4 h-85">
                {children}
              </div>
            </div>
          </>
        ) : null}

        <Navigator viewMode={viewMode} />
      </div>
    </main>
  );
};

export default EditorLayout;
