import PreviewOverlays from '@components/features/preview/components/PreviewOverlays';

//creates the perview video box
const Preview = ({ showOverlays = false }) => (
        <div className='previewScreen__player'>
            <video controls></video>
            {showOverlays
                ? <PreviewOverlays />
                : null
            }
        </div>
);

export default Preview;