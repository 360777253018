import classNames from "classnames";
import { ReactComponent as TextSVG } from "@images/text-icon.svg";
import { ReactComponent as ButtonSVG } from "@images/button-icon.svg";
import useScenarioContext from "@components/pages/scenarioDetailPage/hooks/useScenarioContext";
import { secondsToDisplay } from "@components/primitives/dates";
import { useEffect, useState } from "react";
import { Rnd } from "react-rnd";
//makes a single bar underneath the perview screen

const Overlay = ({ overlay, overlayListRef, overlayNumber }) => {
  //temporary solution
  const videoLength = 100;
  // overlay.timeData.length = 100;

  //gets the with that the overlay is allowed to have
  var clientWidth =
    overlayListRef.current === undefined
      ? 200
      : overlayListRef.current.clientWidth;

  //converts the seconds from timedata to a pixel value
  const ConvertToPx = (val) => {
    // return (clientWidth / overlay.timeData.length) * val;
    return (clientWidth / videoLength) * val;
  };

  //converts the pixel value to seconds to use in timedata
  const ConvertToSec = (val) => {
    // return (val / clientWidth) * overlay.timeData.length;
    return (val / clientWidth) * videoLength;
  };

  //overlaylistref.current takes a while to load, so when it is loaded it re sets the overlaywidth, its ugly i know
  useEffect(() => {
    setOverlayWidth(
      ConvertToPx(overlay.timeData.to) - ConvertToPx(overlay.timeData.from)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientWidth]);

  const { setActiveOverlayId, activeOverlayId } = useScenarioContext();
  const [position, setPosition] = useState({
    from: ConvertToPx(overlay.timeData.from),
    to: ConvertToPx(overlay.timeData.to),
  });
  const [overlayWidth, setOverlayWidth] = useState(
    ConvertToPx(overlay.timeData.to) - ConvertToPx(overlay.timeData.from)
  );

  //Is a variable for all the classnames
  const classElement = classNames(
    "cursor-ew-resize flex items-center my-1 pl-5 border border-primary bg-secondary h-10 w-40 text-white hover:bg-blue-500 truncate",
    {
      activeOverlay: overlay.id === activeOverlayId,
    }
  );

  const onClick = () => {
    setActiveOverlayId(overlay.id);
  };

  //handles the draggable bar
  const onDrag = (e, data) => {
    setPosition({ from: data.x, to: data.x + overlayWidth });
    overlay.timeData.from = ConvertToSec(data.x);
    overlay.timeData.to = ConvertToSec(data.x + overlayWidth);
  };

  //sets the resized postion of the overlay
  const onResize = (e, direction, ref, delta, resizePosition) => {
    setOverlayWidth(overlayWidth + delta.width);
    setPosition({
      from: position.from,
      to: position.to + delta.width,
    });
  };

  return (
    //Using Rnd to make the bar draggable and resizable on the X axis
    <Rnd
      className={classElement}
      size={{
        width: `${overlayWidth}px`,
        height: "40px",
      }}
      position={{ x: position.from, y: overlayNumber * 45 }}
      onDragStop={(e, data) => onDrag(e, data)}
      onResizeStop={(e, direction, ref, delta, position) =>
        onResize(e, direction, ref, delta, position)
      }
      //determents from which side the bar can be dragged
      enableResizing={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
        topRight: false,
      }}
      dragAxis="x"
      bounds=".overlay-list"
      maxWidth={clientWidth}
      minWidth={0}
      onClick={onClick}
    >
      <div className="flex items-center">
        {overlay.type === "text" ? (
          <TextSVG className="w-8 h-8" aria-label="Text" />
        ) : (
          <ButtonSVG className="w-8 h-8" aria-label="Button" />
        )}
        {overlay.textData.text || "Nieuw"}
        <p className="ml-auto pr-5">
          {secondsToDisplay(ConvertToSec(position.from))} -{" "}
          {secondsToDisplay(ConvertToSec(position.to))}
        </p>
      </div>
    </Rnd>
  );
};

export default Overlay;
