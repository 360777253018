import { useMutation, useQueryClient } from 'react-query';
import { deleteCondition } from '@repository/scenarioRepository';

const useDeleteCondition = (scenarioId, sceneId) => {
    const queryClient = useQueryClient();

    const mutation = useMutation((condition) => deleteCondition(scenarioId, sceneId, condition), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['scenario', scenarioId]);
            await queryClient.invalidateQueries(['navigatorScenes', scenarioId]);
        }
    });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useDeleteCondition;