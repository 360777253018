import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import reorder from '@utils/reorder';
import useCreateNavigatorScene from '@hooks/useCreateNavigatorScene';
import useUpdateNavigatorScenePosition from '@hooks/useUpdateNavigatorScenePosition';
import useScenario from '@hooks/useScenario';
import useMedia from '@hooks/useMedia';

const ScenarioDragDrop = ({ children }) => {
    const { scenarioId } = useParams();
    const { data: scenario } = useScenario(scenarioId);
    const { media } = useMedia(scenarioId);
    const { mutate: createNavigatorScene } = useCreateNavigatorScene(scenarioId);
    const { mutate: updateNavigatorScenePosition } = useUpdateNavigatorScenePosition(scenarioId);

    const onDragEnd = (result) => {
        const { source, destination, draggableId } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === 'mediacomposer' && source.droppableId === 'navigator') {
            return;
        }

        if (destination.droppableId === 'navigator' && source.droppableId === 'mediacomposer') {
            const draggedScene = media?.filter((mediaItem) => mediaItem.sceneId === draggableId.split('-')[0])[0];
            createNavigatorScene({ draggedScene: draggedScene, position: destination.index });
            return;
        }

        const draggedScene = scenario.navigator.filter((scene) => scene.id === draggableId.split('-')[0])[0];

        updateNavigatorScenePosition({
            id: draggedScene.id,
            position: destination.index
        });

        reorder(scenario.navigator, source.index, destination.index);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd} isMovementAllowed={false}>
            {children}
        </DragDropContext>
    );
};

export default ScenarioDragDrop;