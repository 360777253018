import { secondsToDisplay } from "@components/primitives/dates";

const MediaItem = ({ mediaItem }) => (
    <>
        <div className='absolute text-white max-w-75'>
            {mediaItem.sceneTitle}
        </div>
        <div className='absolute text-white right-2 bottom-0'>
            {secondsToDisplay(mediaItem.duration)}
        </div>
    </>
);

export default MediaItem;