import { Component } from 'react';
import ErrorPage from '@components/pages/errorPage/ErrorPage';
class ErrorBoundary extends Component {
    state = {
        hasError: false,
        error: null,
        errorInfo: null,
    };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            ...this.state,
            error,
            errorInfo,
        });
    }

    render() {
        if (!this.state.hasError) {
            return this.props.children;
        }

        return (
            <ErrorPage error={this.state.error?.message} errorInfo={this.state.errorInfo?.componentStack} />
        );
    }
}

export default ErrorBoundary;
