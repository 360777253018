import { Helmet } from 'react-helmet';
import { TitlePath } from '@routing/appUrls';
import useScenarios from '@hooks/useScenarios';
import Loader from '@components/primitives/loader/Loader';
import { i18n } from '@utils/i18n';
import Scenario from '@components/pages/scenariosPage/component/Scenario';
import useLive from '@hooks/useLive';

const ScenariosPage = () => {
    const { data: scenarios, isLoading: isLoadingScenarios } = useScenarios();
    const { data: liveScenarios, isLoading: isLoadingLiveScenarios } = useLive();

    const checkIfLive = (scenarioId) => {
        return liveScenarios?.find((scenario) => scenario.id === scenarioId) ? true : false;
    };

    const getLiveScenario = (scenarioId) => {
        return liveScenarios?.find((scenario) => scenario.id === scenarioId);
    };

    return (
        <>
            <Helmet>
                <title>{TitlePath.scenarios}</title>
            </Helmet>
            {(isLoadingScenarios || isLoadingLiveScenarios) ? (
                <Loader />
            ) : (
                <div className='m-12'>
                    <h3 className='text-primary text-3xl'>{i18n.scenarios}</h3>
                    <div className='w-full h-full'>
                        {scenarios
                            ? scenarios.map((scenario) => <Scenario key={scenario.id} liveScenario={getLiveScenario(scenario.id)} isLive={checkIfLive(scenario.id)} scenario={scenario} />)
                            : <p>{i18n.noScenarios}</p>
                        }
                    </div>
                </div>
            )}
        </>
    );
};


export default ScenariosPage;