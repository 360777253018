import { useMutation, useQueryClient } from 'react-query';
import { updateNavigatorScenePosition } from '@repository/scenarioRepository';

const useUpdateNavigatorScenePosition = (scenarioId) => {
    const queryClient = useQueryClient();
    const mutation = useMutation(scene => {
        return updateNavigatorScenePosition(scenarioId, scene);
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['scenario', scenarioId]);
            await queryClient.invalidateQueries(['navigatorScenes', scenarioId]);
        }
    });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useUpdateNavigatorScenePosition;