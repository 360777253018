import { useParams } from "react-router-dom";
import useScenario from "@hooks/useScenario";
import Overlay from "@components/features/overlays/Overlay";
import useScenarioContext from "@components/pages/scenarioDetailPage/hooks/useScenarioContext";
import { useEffect, useRef } from 'react';

//creates a bar for each interactable underneath the preview
const Overlays = () => {
  const { sceneId, scenarioId } = useParams();
  const { data: scenario } = useScenario(scenarioId);
  const activeScene = scenario?.navigator.find(
    (navigatorScene) => navigatorScene.id === sceneId
  );
  const { setActiveOverlayId, activeOverlayId } = useScenarioContext();
  const overlayListRef = useRef();

  //set the active overlay to the first overlay in the scene
  useEffect(() => {
    if (activeOverlayId || !activeScene?.overlays[0]?.id) {
      return;
    }

    setActiveOverlayId(activeScene?.overlays[0]?.id);
  }, [activeOverlayId, activeScene])

  if (!activeScene) {
    return null;
  }


  return (
    <div className="flex flex-col gap-3.5">
      <div
        className="h-1/2 w-full border border-primary bg-primary overflow-y-scroll overflow-x-hidden overlay-list"
        ref={overlayListRef}
      >
        {activeScene.overlays
          ? activeScene.overlays.map((overlay) => (
              <Overlay
                key={overlay.id}
                overlay={overlay}
                overlayListRef={overlayListRef}
                overlayNumber={activeScene.overlays.indexOf(overlay)}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default Overlays;
