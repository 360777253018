import { useMutation, useQueryClient } from 'react-query';
import { deleteOverlay } from '@repository/scenarioRepository';

const useDeleteOverlay = (scenarioId) => {
    const queryClient = useQueryClient();
    const mutation = useMutation(data => {
        const { sceneId, overlayId } = data;
        return deleteOverlay(scenarioId, sceneId, overlayId);
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['scenario', scenarioId]);
            await queryClient.invalidateQueries(['navigatorScenes', scenarioId]);
        }
    });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useDeleteOverlay;