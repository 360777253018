import { useMutation, useQueryClient } from 'react-query';
import { createTestScenario } from '@repository/scenarioRepository';

const useCreateTestScenario = (scenarioId) => {
    const queryClient = useQueryClient();
    const mutation = useMutation(data => {
        return createTestScenario(scenarioId, data);
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['testscenarios', scenarioId]);
        }
    });

    const { mutate, isLoading, isError, data } = mutation;
    return { mutate, isLoading, isError, data };
};

export default useCreateTestScenario;