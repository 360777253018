import { useQuery } from 'react-query';
import { getScenarioStyles } from '@repository/scenarioRepository';

const useScenarioStyles = (scenarioId) => {
    const { data, isLoading, isError } = useQuery(['styles', scenarioId], () => getScenarioStyles(scenarioId), {
        refetchOnWindowFocus: true,
    });

    return { data, isLoading, isError };
};

export default useScenarioStyles;