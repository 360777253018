import { Rnd } from 'react-rnd';
import { useEffect, useState } from 'react';
import useUpdateOverlay from '@components/features/overlays/hooks/useUpdateOverlay';
import { useParams } from 'react-router-dom';
import useScenarioContext from '@components/pages/scenarioDetailPage/hooks/useScenarioContext';
import { useQueryClient } from 'react-query';

const PreviewOverlayV2 = ({ overlay, className, overlaysRef, renderChild }) => {
    const { scenarioId, sceneId } = useParams();
    const { setActiveOverlayId } = useScenarioContext();

    const [width, setWidth] = useState(overlay.position.width);
    const [height, setHeight] = useState(overlay.position.height);
    const [left, setLeft] = useState(overlay.position.left);
    const [top, setTop] = useState(overlay.position.top);
    const [videoWidth, setVideoWidth] = useState(null);
    const [videoHeight, setVideoHeight] = useState(null);
    const [overlayUpdated, setOverlayUpdated] = useState(false);
    const [vw, setVw] = useState(null);

    const client = useQueryClient();
    const { mutate } = useUpdateOverlay(scenarioId);

    const updateOverlay = async () => {
        mutate({
            sceneId,
            overlayId: overlay.id,
            overlay: {
                ...overlay,
                position: {
                    width: width || 10,
                    height: height || 10,
                    left: left || 0,
                    top: top || 0,
                }
            }
        }, {
            onSuccess: () => {
                client.invalidateQueries(['scenario', scenarioId]);
            }
        });
    }

    useEffect(async () => {
        if (!overlayUpdated) {
            return;
        }

        await updateOverlay();

        setOverlayUpdated(false);
    }, [overlayUpdated]);

    const setVideoDimensions = () => {
        setVideoWidth(overlaysRef.current.offsetWidth);
        setVideoHeight(overlaysRef.current.offsetHeight);
    }

    const setVwSize = () => {
        setVw(overlaysRef.current.offsetWidth / 100);
    }

    useEffect(() => {
        setVideoDimensions();
        setVwSize();
        window.addEventListener('resize', setVideoDimensions);
        window.addEventListener('resize', setVwSize);

        return () => {
            window.removeEventListener('resize', setVideoDimensions);
            window.removeEventListener('resize', setVwSize);
        }
    }, []);

    return (
        <Rnd
            className={className}
            size={{
                width: `${width}%`,
                height: `${height}%`,
            }}
            position={{
                x: left / 100 * videoWidth,
                y: top / 100 * videoHeight,
            }}
            onDragStop={(e, d) => {
                setLeft(d.x / videoWidth * 100);
                setTop(d.y / videoHeight * 100);
                setOverlayUpdated(true);
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                setWidth(ref.offsetWidth / videoWidth * 100);
                setHeight(ref.offsetHeight / videoHeight * 100);
                setLeft(position.x / videoWidth * 100);
                setTop(position.y / videoHeight * 100);
                setOverlayUpdated(true);
            }}
            bounds="parent"
            onClick={() => {
                setActiveOverlayId(overlay.id);
            }}
        >
            {renderChild(vw)}
        </Rnd>
    );
};

export default PreviewOverlayV2;
