import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { i18n } from "@utils/i18n";
import useScenario from "@hooks/useScenario";
import useUpdateNavigatorScene from "@hooks/useUpdateNavigatorScene";
import Toast from "@components/primitives/toast/Toast";
import Condition from "@components/features/sceneSettings/components/Condition";
import useDeleteCondition from "@hooks/useDeleteCondition";
import useCreateCondition from "@hooks/useCreateCondition";
import { ReactComponent as ConditionSVG } from "@images/condition2.svg";
import useMedia from "@hooks/useMedia";

const Settings = () => {
  const { sceneId, scenarioId } = useParams();
  const { data: scenario } = useScenario(scenarioId);
  const [sceneDescription, setSceneDescription] = useState("");
  const { mutate: mutateUpdateNavigator, error: errorUpdateNavigator } =
    useUpdateNavigatorScene(scenarioId);
  const [conditions, setConditions] = useState([]);
  const { mutate: deleteCondition } = useDeleteCondition(scenarioId, sceneId);
  const { mutate: createCondition } = useCreateCondition(scenarioId, sceneId);

  const activeScene = scenario?.navigator.find((scene) => {
    return scene.id === sceneId;
  });
  const { media } = useMedia(scenarioId);

  const currentMedia = media?.find((media) => {
    return media.sceneId === activeScene?.sceneId;
  });

  const onDelete = (condition) => {
    deleteCondition(condition?.id);
  };

  const onAdd = () => {
    createCondition({
      context: 1,
      trigger: 1,
      value: "",
    });
  };

  useEffect(() => {
    if (!activeScene) {
      return;
    }

    setSceneDescription(activeScene.description);
    setConditions(activeScene.conditions);
  }, [activeScene]);

  useEffect(() => {
    if (!errorUpdateNavigator) {
      return;
    }

    const errorMessage = errorUpdateNavigator.response.data.details[0].message;
    Toast("error", errorMessage);
  }, [errorUpdateNavigator]);

  const handleDescriptionBlur = () => {
    if (!activeScene) {
      return;
    }

    const newScene = {
      ...activeScene,
      description: sceneDescription,
    };

    mutateUpdateNavigator(newScene);
  };

  return (
    <div>
      <label className="text-xl" htmlFor="description">
        {currentMedia?.sceneTitle}
      </label>
      <input
        id="description"
        className="form-control italic"
        maxLength={20}
        placeholder={i18n.media.placeholderDescription}
        value={sceneDescription}
        onChange={(e) => setSceneDescription(e.target.value)}
        onBlur={handleDescriptionBlur}
      />
      <button
        className="rounded bg-green-500 hover:bg-green-700 flex items-center gap-2 p-2 text-white my-3"
        onClick={onAdd}
      >
        {i18n.media.addCondition}
        <ConditionSVG className="w-4 h-4" />
      </button>
      {conditions && conditions.length
        ? conditions.map((condition, index) => (
            <Condition
              key={index}
              deleteCondition={onDelete}
              condition={condition}
            />
          ))
        : null}
    </div>
  );
};

export default Settings;
